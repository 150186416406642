

.counter_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.counter_item{
    width: 33%;
    text-align: center;
    
}

.counter_number, 
.counter_title{
    background: var(--counter-color);
    background-size: 100%   100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}    

.counter_number{
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 10px;
}

.counter_title{
    font-size: 1.5rem;
}