.about_wrapper {
    display: flex;
    column-gap: 2rem;
    margin-top: 25px;
}

.about_content, .about_img {
    width: 50%;
}

.about_img img {
    width: 100%;
    height: 50%;
    margin-top: 500px;
}


    .about_content {
        flex: 4;
        padding: 20px;
        text-align: center;
    }
    


.about_img {
    flex: 1;
    text-align: center;
}

.subtitle {
    font-size: 1.2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
}

.highlight {
    color: var(--secondary-color);
    margin-bottom: 20px;
}

.description {
    margin-bottom: 20px;
}

.choose_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.choose_item {
    background: var(--card-bg);
    padding: 20px;
    text-align: center;
    border-radius: .5rem;
    flex: 1 1 calc(33.333% - 20px); /* three items per row with gap */
    box-sizing: border-box;
}

.choose_us-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: var(--icon-bg);
    border-radius: 5px;
    margin: 0 auto 15px;
    border: 1px solid var(--primary-color);
}

.choose_us-icon i {
    font-size: 2rem;
    color: var(--primary-color);
}

.choose_title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: var(--primary-color);
}

.highlight {
    color: #9265DF;
}

.light-theme .choose_us-icon {
    background-color: var(--primary-color);
}

.light-theme .choose_us-icon i {
    color: #fff;
}

