

.footer {
    padding: 60px 0px;
    background: var(--body-bg);
    color: var(--heading-color);
}

.footer_wrapper {
    display: flex;
    justify-content: space-between;
    column-gap: 2.5rem;
}

.footer_logo {
    width: 40%;
}

.footer_quick-links {
    width: 20%;
}

.footer_title {
   color: var(--primary-color);
   font-size: 1.7rem;
   font-weight: 600;
   margin-bottom: 20px;
  
}

.small_text{
    font-size: 0.9rem;
    margin-top: 10px;
}

.small_text.description  {
    font-size: 0.9rem;
    margin-top: 30px;
 
}

.quick_links-title {
    font-size: 1.1rem;
    margin-bottom: 20px;
    font-weight: 500;
    color: var(--primary-color);
}

.quick_links-list {
    list-style: none;
    padding: 0;
}

.quick_links-item {
    margin-bottom: 30px;
}

.quick_links-item a {
    margin-bottom: 30px;
    font-weight: 300;
    cursor: pointer;
}

.quick_links-item a:hover {
    color: var(--primary-color);
}

.copyright {
    text-align: center;
    margin-top: 50px;
    color: var(--primary-color)
}

.light-theme .quick_links-item a{
    font-weight: 500;
}

.light-theme .copyright{
    color: #969494;
}