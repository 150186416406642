.slider_content-top {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .slider_wrapper {
    width: 70%;
    margin: auto;
  }
  
  .slider_item {
    background: var(--testimonial-bg);
    padding: 35px 20px;
    text-align: center;
  }
    
  .customer_details {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    justify-content: center;
    margin-top: 40px;
  }
  
  .customer_img {
    width: 60px;
    height: 60px;
  }
  
  .customer_img img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .customer_name {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--heading-color);
  }
  