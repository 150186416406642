

.section_newsletter{
    background: var(--newsletter-bg);
    padding: 4rem 0;
}

.newletter_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.newletter_content, .newletter_form{
    width: 50%;
}

.newsletter_form input{
    padding: 0.7rem 1.5rem;
    border: none;
    outline: none;
    font-size: 1rem;
    cursor: pointer;
    
}

.newsletter_form{
    padding: 0.4rem;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
}

.light-theme .section_newsletter{
    background: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
  );
}    

.light-theme .subscribe_btn{
    background-color: var(--primary-color);
    color: #fff;
}    