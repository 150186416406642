/* google fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* css variables */
:root {
  --primary-color: #379cf6;
  --heading-color: #fff;
  --btn-primary-bg: #379cf6;
  --btn-secondary-bg: #090238;
  --team-card-bg: #1b2352;
  --link-color: #fff;
  --link-active: #379cf6;
  --card-bg: linear-gradient(
    45deg,
    rgba(15, 18, 61, 1) 33%,
    rgba(15, 18, 61, 1) 52%,
    rgba(11, 41, 69, 1) 84%
  );
  --counter-color: linear-gradient(
    90deg,
    #02bba6 30%,
    rgb(171, 29, 176) 55%,
    rgb(240, 103, 23) 75%
  );
  --body-bg: rgba(6, 11, 26, 1);
  --newsletter-bg: linear-gradient(
    90deg,
    rgba(27, 9, 115, 1) 20%,
    rgba(23, 9, 96, 1) 38%,
    rgba(14, 9, 56, 1) 100%
  );
  --testimonial-bg: #1a0f4f;
  --font-name: "inter", sans-serif;
  --small-text-color: rgba(255, 255, 255, 0.774);
  --icon-bg:#151e4f;
}

.light-theme {
  --body-bg: rgba(235, 235, 235, 0.61);
  --heading-color: #0c123d;
  --btn-secondary-bg: #816aff;
  --btn-primary-bg: #08197c;
  --primary-color: #816aff;
  --counter-color: #fff;
  --counter-section-bg: #816aff;
  --link-color: #0c123d;
  --link-active: #816aff
  --newsletter-bg: linear-gradient(
    180deg,
    rgba(189, 243, 255, 1) 29%,
    rgba(193, 243, 255, 1) 50%,
    rgba(226, 250, 255, 1) 78%
  );
  --counter-color: linear-gradient(
    90deg,
    #02bba6 30%,
    rgb(171, 29, 176) 55%,
    rgb(240, 103, 23) 75%
  );
  --small-text-color:#000;
  --card-bg:#fff;
  --testimonial-bg: #f7f7f7;
  --team-card-bg: #fff;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  background: var(--body-bg);
  font-family: var(--font-name);
}

a {
  text-decoration: none;
  color: var(--link-color);
}

h1,h2{
    color: var(--heading-color);
    font-size: 2.5rem;
}

section {
  padding: 60px 0px;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px;
}

.description {
  color: var(--small-text-color);
  font-size: 0.9rem;
  line-height: 30px;
}

.subtitle {
  font-size: 1.2rem;
  color: var(--primary-color);
  font-weight: 400;
  margin-bottom: 20px;
}

.light-theme .counter {
  color: var(--counter-section-bg);
}

