.services_top-content {
    text-align: center;
    margin-bottom: 40px; 
}

.service_item-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.services_item {
    background: var(--card-bg);
    padding: 35px 15px;
    text-align: center;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 300px; /* Adjust the height as needed */
}

.service_icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--icon-bg);
    border-radius: 5px;
    margin-bottom: 30px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
}

.light-theme .service_icon {
    background-color: var(--primary-color);
}

.light-theme .service_icon i {
    color: #fff;
}

.service_icon i {
    font-size: 2rem;
    color: var(--primary-color);
}

.service_title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 30px;
    color: var(--primary-color);
}
