.highlight {
    
    position: relative;
    color: #190ae9;
    font-size: 40px;
    font-weight: 700;
    font-style: italic;
    font-family: 'inter', sans-serif;
    animation: typing 7s steps(30, end) 1, blink-caret 0.75s step-end infinite;
    white-space: nowrap;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
}


@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
}

@keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: black; }
}

