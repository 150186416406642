


.hero_wrapper {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.hero_content, .hero_img {
    width: 50%;
}   

.hero_img img {
    width: 100%;
    
}

.hero_content h2 {
    font-size: 3rem;

}

.heading.hero_h2 {
    color: #9265DF;
}

h2 {
   padding-top: 5px;
   padding-bottom: 5px;
}

.hero_btns {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.primary_btn, .secondary_btn {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.primary_btn {
    background: var(--btn-secondary-bg);
    color: #fff;
}

.secondary_btn {
    background: var(--btn-primary-bg);
    color: #fff;
}