.header {
    width: 100%;
    height: 80px;
    line-height: 80px;
    transition: background-color 0.3s, color 0.3s; /* Smooth transition for background and text color */
}

.nav_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.menu {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    list-style: none;
}

.menu_link {
    font-weight: 400;
    font-size: 0.9rem;
    cursor: pointer;
    transition: color 0.3s; /* Smooth transition for link color */
}

.menu_link:hover {
    color: var(--link-active);
}

.logo h2 {
    color: var(--primary-color); 
    transition: color 0.3s; /* Smooth transition for logo color */
}

.light_mode span {
    color: rgba(255, 255, 255, 0.637);
    display: flex;
    align-items: center;
    column-gap: .4rem;
    font-size: .8rem;
    transition: color 0.3s; /* Smooth transition for light mode text color */
}

.light-theme .light_mode span {
    color: rgba(0, 0, 0, 0.637);
}

.header_shrink {
    width: 100%;
    height: 80px;
    line-height: 80px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;
    background: var(--body-bg);
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2);
}

.light-theme .header {
    background-color: #fff; /* Light theme background color */
    color: var(--primary-color); /* Light theme text color */
}

.light-theme .menu_link {
    color: var(--primary-color);
}

.light-theme .menu_link:hover {
    color: var(--link-active);
}

.light-theme .logo h2 {
    color: var(--primary-color);
}

.light-theme .header_shrink {
    background-color: #fff;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.1); /* Lighter shadow for light theme */
}
