.contact_section {
    padding: 60px 0;
    background: var(--body-bg);
    color: var(--heading-color);
}

.contact_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.contact_content {
    max-width: 600px;
    width: 100%;
}

.subtitle {
    font-size: 1.2rem;
    color: var(--primary-color);
    margin-bottom: 20px;
}

h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.description {
    font-size: 1rem;
    margin-bottom: 30px;
}

.contact_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form_group {
    width: 100%;
}

.form_group input, 
.form_group textarea {
    width: 100%;
    padding: 15px;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    font-size: 1rem;
    outline: none;
    resize: none;
}

.form_group textarea {
    height: 150px;
}

.submit_btn {
    padding: 15px 30px;
    border: none;
    background: var(--primary-color);
    color: #fff;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
}

.submit_btn:hover {
    background: var(--secondary-color);
}

/* Media queries for larger screens */
@media (min-width: 768px) {
    .contact_wrapper {
        flex-direction: row;
        justify-content: center;
    }

    .contact_content {
        text-align: left;
    }
}
